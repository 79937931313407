import React, {Fragment, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import Lightbox from 'react-image-lightbox';
import {Container, Typography} from '@material-ui/core';
const DOMPurify = require('dompurify')(window);

import './Moment.css';

DOMPurify.addHook('afterSanitizeAttributes', function (node) {
  // Set all elements owning target to target=_blank safely
  // (see https://github.com/cure53/DOMPurify/issues/317 and https://web.dev/external-anchors-use-rel-noopener/)
  if ('target' in node) {
    node.setAttribute('target', '_blank');
    node.setAttribute('rel', 'noreferrer noopener nofollow');
  }
});

function Moment(props) {
  const [showLightbox, setShowLightbox] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const {id} = useParams();
  const moment = props.moments.find(moment => moment.id === Number(id));

  useEffect(() => {
    window.scrollTo(0, 0);
    props.updateDrawerButton(null, '');
  }, []);

  if (!moment) {
    return null;
  }

  // Keep an array of just images and captions (no videos) for use with the lightbox
  const imagePathsAndCaptions = moment.paths_and_captions.filter(pathAndCaption => (
    !pathAndCaption.source || !pathAndCaption.source.includes('youtube')
  ));

  const toggleLightbox = (newShowLightbox, newSource) => {
    const newSelectedIndex = imagePathsAndCaptions.findIndex(pathAndCaption => pathAndCaption.source == newSource);
    if (newShowLightbox !== showLightbox || newSelectedIndex !== selectedIndex) {
      setShowLightbox(newShowLightbox);
      setSelectedIndex(newSelectedIndex);
    }
  };

  return (<>
    <Container className='the-Moment-Container' maxWidth='lg' disableGutters={true}>
      <Typography className='the-Moment-Title' variant='h3' color='secondary'>{moment.title}</Typography>
      {/* Note: If there's no description or first caption, the spacing is better with an empty description element. */}
      {(moment.description || !moment.paths_and_captions[0].caption) &&
        <Typography className='the-Moment-Description' gutterBottom
          dangerouslySetInnerHTML={{'__html': DOMPurify.sanitize(moment.description)}}
        />}
      {moment.paths_and_captions.map((pathAndCaption, index) => {
        return (<Fragment key={index}>
          {!!pathAndCaption.caption &&
            <Typography className='the-Moment-Caption'
              dangerouslySetInnerHTML={{'__html': DOMPurify.sanitize(pathAndCaption.caption)}} />}
          {!!pathAndCaption.source && pathAndCaption.source.includes('youtube') &&
            <div className='the-Moment-VideoContainer'>
              <iframe className='the-Moment-Video' src={pathAndCaption.source} allowFullScreen />
            </div>}
          {!!pathAndCaption.source && !pathAndCaption.source.includes('youtube') &&
            <img className='the-Moment-Image' src={pathAndCaption.source}
              onClick={() => toggleLightbox(true, pathAndCaption.source)} />}
        </Fragment>);
      })}
    </Container>

    {showLightbox && imagePathsAndCaptions && imagePathsAndCaptions[selectedIndex] && (
      <Lightbox
        mainSrc={imagePathsAndCaptions[selectedIndex].source || 'NONE'}
        nextSrc={imagePathsAndCaptions[selectedIndex + 1] && (imagePathsAndCaptions[selectedIndex + 1].source || 'NONE')}
        prevSrc={imagePathsAndCaptions[selectedIndex - 1] && (imagePathsAndCaptions[selectedIndex - 1].source || 'NONE')}
        imageCaption={imagePathsAndCaptions[selectedIndex].source ? imagePathsAndCaptions[selectedIndex].caption : ''}
        imageLoadErrorMessage={imagePathsAndCaptions[selectedIndex].caption}
        onCloseRequest={() => toggleLightbox(false)}
        onMovePrevRequest={() => setSelectedIndex(selectedIndex - 1)}
        onMoveNextRequest={() => setSelectedIndex(selectedIndex + 1)}
      />
    )}
  </>);
}

export default Moment;
